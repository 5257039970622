<template>
  <div class="home">
    <div class="amber accent-4 d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar">
      <HeaderBar />
    </div>
    <div>
      <Menu />
    </div>
    <div class="height-div"></div>

    <!--Web Banner-->
    <div class="banner-div d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <template>
        <v-carousel height="auto" hide-delimiters show-arrows-on-hover>
          <v-carousel-item v-for="(banner, i) in bannerImages" :key="i" :src="banner.image"></v-carousel-item>
        </v-carousel>
      </template>

    </div>
    <!--Web Banner ENd-->

    <!--Mobile Banner-->
    <div class="mobile-banner-div d-block d-sm-block d-md-none d-lg-none d-xl-none">
      <template>
        <v-carousel height="auto" hide-delimiters show-arrows-on-hover>
          <v-carousel-item v-for="(banner, i) in bannerImages" :key="i" :src="banner.image"></v-carousel-item>
        </v-carousel>
      </template>

    </div>
    <!--Mobile Banner End-->

    <v-container>
      <div class="productdiv" ref="productdiv1">
        <marquee-text :duration="50" :repeat="1" class=" secondary--text text--darken-4">
          <h3 v-html="banner.scroll_content"></h3>
        </marquee-text>
        <v-card outlined color="accent" dark class="mx-auto">
          <div v-html="banner.details" class="text-center white--text banner-text"></div>
        </v-card>
      </div>

      <v-row justify="space-around" dense class="mt-3 mb-n5">
        <v-col cols="12" sm="12" md="3">
          <div>
          <v-btn color="primary" outlined block height="40" @click="priceListOpen">Download Price List</v-btn>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <v-text-field outlined prepend-inner-icon="mdi-magnify" v-model="search" x-large dense
            @input="filterProducts" placeholder="Search"></v-text-field>
        </v-col>
      </v-row>
      <div v-for="(category, i) in products" :key="i">
        <div v-if="category.products.length > 0">
          <h3 class="text-decoration-underline primary--text text--darken-4 text-center mb-5 mt-5">
            {{ category.categoryname }}
          </h3>

          <v-card class="mx-auto mb-5" outlined v-for="(product, k) in category.products" :key="k">

            <!--Web Screen-->
            <div class="d-block d-sm-flex d-md-flex d-lg-flex d-xl-flex">

              <div class="d-none d-sm-flex d-md-flex d-lg-flex align-center align-self-center ml-3" style="width:35%;">
                <div>
                  <v-avatar size="50" tile class="align-center align-self-center mr-5"
                    @click="imageDialogOpen(product.image)">
                    <v-img :src="product.image"></v-img>
                  </v-avatar>

                  <v-avatar class="align-center align-self-center mr-5" tile @click="youtubeOpen(product.youtube_url)"
                    v-if="product.youtube_url">
                    <v-icon color="red" size="50">
                      mdi-youtube
                    </v-icon>
                  </v-avatar>
                </div>
                <div>
                  <h3 class="text-wrap  primary--text text--darken-4" style="max-width: 25rem;font-size: 17px;">
                    {{ product.name }}
                  </h3>
                  <h3 class="text-wrap mt-2 secondary--text text--darken-4" style="max-width: 25rem;font-size: 17px;"
                    v-if="product.tamil_name">
                    {{ product.tamil_name }}
                  </h3>
                </div>
              </div>

              <div class="d-flex d-sm-none d-md-none d-lg-none align-center align-self-center ml-3 mt-3">
                <div>
                  <v-avatar size="50" tile class="align-center align-self-center mr-5"
                    @click="imageDialogOpen(product.image)">
                    <v-img :src="product.image"></v-img>
                  </v-avatar>

                  <v-avatar class="align-center align-self-center mr-5" tile @click="youtubeOpen(product.youtube_url)"
                    v-if="product.youtube_url">
                    <v-icon color="red" size="50">
                      mdi-youtube
                    </v-icon>
                  </v-avatar>
                </div>
                <div>
                  <h3 class="text-wrap mt-2 primary--text text--darken-4" style="max-width: 25rem;font-size: 17px;">
                    {{ product.name }}
                  </h3>
                  <h3 class="text-wrap mt-2 secondary--text text--darken-4" style="max-width: 25rem;font-size: 17px;"
                    v-if="product.tamil_name">
                    {{ product.tamil_name }}
                  </h3>
                </div>
              </div>



              <div class="d-none d-sm-block d-md-block d-lg-block" style="width: 65%;">
                <v-row dense no-gutters>
                  <v-col cols="12" sm="7" md="7">
                    <div
                      class="d-flex d-sm-flex d-md-flex d-lg-flex align-center align-self-center justify-space-between"
                      style="width:100%;">
                      <div class="d-flex justify-space-between  flex-row  text-wrap mt-md-5" style="width:100%;">
                        <h3 class="text-decoration-line-through
                        error--text ma-2" style="font-size: 17px;">
                          Rs. {{ product.rate }}
                        </h3>
                        <!-- <h3 class="text-decoration-line-through error--text ma-2" style="font-size: 17px;">
                          Rs. {{ product.offerPrice }}
                        </h3> -->
                        <h3 class="green--text text--darken-4 ma-2" style="font-size: 17px;">
                          Rs. {{ product.salesprice }}
                        </h3>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <div class="d-flex d-sm-flex d-md-flex d-lg-flex align-center align-self-center justify-start">
                      <div class="qty-alt rounded-xl ma-3">
                        <div class="d-flex qty-btn">
                          <v-btn class="ma-1 mt-2 ml-5" x-small outlined fab color="black" @click="decrement(product)">
                            <v-icon outlined fab size="10">mdi-minus</v-icon>
                          </v-btn>
                          <v-text-field outlined v-model="product.qty" dense class="mt-1 qty-textbox dense"
                            @change="productQtyUpdate(product)" type="number"></v-text-field>
                          <v-btn class="ma-1 mt-2" x-small outlined fab color="black" @click="increment(product)">
                            <v-icon outlined fab size="10">mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div class="justify-space-between text-center ma-3">
                        <h3 class="accent--text">
                          Rs.{{ product.totalSalesPrice }}
                        </h3>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>


              <div class="d-block d-sm-none d-md-none d-lg-none">
                <v-row dense no-gutters>
                  <v-col cols="12" sm="7" md="7">
                    <div
                      class="d-flex d-sm-flex d-md-flex d-lg-flex align-center align-self-center justify-space-between"
                      style="width:100%;">
                      <div class="d-flex justify-space-between  flex-row  text-wrap mt-md-5" style="width:100%;">
                        <div>
                          <h3 class="text-decoration-line-through error--text ma-2" style="font-size: 17px;">
                            Rs. {{ product.rate }}
                          </h3>
                        </div>
                        <!-- <div>
                          <h3 class="text-decoration-line-through error--text ma-2" style="font-size: 17px;">
                            Rs. {{ product.offerPrice }}
                          </h3>
                        </div> -->
                        <div>
                          <h3 class="green--text text--darken-4 ma-2" style="font-size: 17px;">
                            Rs. {{ product.salesprice }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <div class="d-flex d-sm-flex d-md-flex d-lg-flex align-center align-self-center justify-start">
                      <div class="qty-alt rounded-xl ma-3">
                        <div class="d-flex qty-btn">
                          <v-btn class="ma-1 mt-2 ml-5" x-small outlined fab color="black" @click="decrement(product)">
                            <v-icon outlined fab size="10">mdi-minus</v-icon>
                          </v-btn>
                          <v-text-field outlined v-model="product.qty" dense class="mt-1 qty-textbox dense"
                            @change="productQtyUpdate(product)" type="number"></v-text-field>
                          <v-btn class="ma-1 mt-2" x-small outlined fab color="black" @click="increment(product)">
                            <v-icon outlined fab size="10">mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div class="justify-space-between text-center ma-3">
                        <h3 class="accent--text">
                          Rs.{{ product.totalSalesPrice }}
                        </h3>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

            </div>
            <!--Web Screen End-->

            <!--Mobile Screen-->

            <!--Mobile Screen End-->

          </v-card>


        </div>
      </div>
    </v-container>

    <v-dialog v-model="imageDialog" width="500">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="imageDialog = false" color="primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-img :src="clickedImage"></v-img>
      </v-card>
    </v-dialog>


    <!-- <v-dialog v-model="popupDialog">
      <v-card class="mx-auto" max-width="600">
        <v-toolbar>
          <h3>Disclaimer</h3>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="popupDialog = false" color="primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-img class="align-end text-white" height="auto" src="https://admin.ptscrackers.com/storage/pop-up.png"
          contain>
        </v-img>
      </v-card>
    </v-dialog> -->


    <v-dialog v-model="youtubeDialog" persistent>
      <v-card class="text-center">
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="youtubeDialogClose" color="primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <iframe class="embed-responsive-item" :src="clickedUrl" allowfullscreen></iframe> -->

        <iframe width="auto" height="400" :src="clickedUrl" frameborder="0" allowfullscreen></iframe>

      </v-card>
    </v-dialog>

    <!--Cart Error-->
    <v-dialog v-model="errorDialog" max-width="590">
      <v-card>
        <v-card-title class="text-h5"> </v-card-title>
        <v-card-text>
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in cartError.response" :key="i">
              <h3 v-html="error"></h3>
            </div>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Cart Error-->


    <loading v-if="loading" />


    <footerCommon />

    <v-snackbar v-model="snackbar" :timeout="timeout" color="primary" elevation="24">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<style scoped>
.v-breadcrumbs {
  padding: 0px !important;
}

.top-bar {
  position: fixed;
  width: 100%;
  z-index: 111;
}

.v-application p {
  margin-bottom: 0px;
}

.qty-alt {
  border: 1px solid #ddd;
  max-width: 200px;
  max-width: 300px;
  text-align: center;
  font-weight: 500;
  height: 50px;
}

.qty-btn {
  margin-left: 0% !important;
  margin-right: 5% !important;
}

/* .qty-textbox {
  max-width: 100%;
  text-align: center !important;
} */

.qty-textbox .v-text-field {
  text-align: center !important;
}

.height-div {
  height: 10px !important;
}

.banner-div {
  margin-top: 145px !important;
}

.mobile-banner-div {
  margin-top: 115px !important;
}

/*.productdiv {
  margin-top: 165px !important;
}
*/
.banner-text {
  line-height: 50px;
}

.footer-card {
  width: 100%;
}

.product-cart-div {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.product-cart-card {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.cart-qty-alt {
  border: 1px solid #ddd;
  width: 45%;
  text-align: center;
  font-weight: 500;
  height: 50px;
}

.cart-qty-alt .qty-btn {
  margin-left: 8% !important;
  margin-right: 8% !important;
}

.cart-qty-textbox {
  max-width: 50%;
  text-align: center !important;
}

.cart-qty-textbox .v-text-field {
  text-align: center !important;
}

.mobile-cart-qty-alt {
  border: 1px solid #ddd;
  width: 100%;
  text-align: center;
  font-weight: 500;
  height: 50px;
}

.mobile-cart-qty-alt .qty-btn {
  margin-left: 0% !important;
  margin-right: 0% !important;
}

/*
.productdiv::before {
    content: "";
    position: absolute;
    top: 24%;
    left: 6%;
    transform: skew(20deg) translateX(-72%);
    width: 73%;
    height: 20%;
    background-color: #ffd600;
     opacity: .7;
    z-index: 9999;
}
.productdiv::after {
    content: "";
    position: absolute;
    top: 24%;
    right: 6%;
    transform: skew(20deg) translateX(-72%);
    width: 73%;
    height: 20%;
    background-color: #ffd600;
     opacity: .7;
}*/
</style>

<script>
import { mapActions, mapGetters } from "vuex";
// import { required, email } from "vuelidate/lib/validators";
// import { required, email, requiredIf } from "vuelidate/lib/validators";
//import store from "../store";

import MarqueeText from "vue-marquee-text-component";
import { VUE_APP_BASEURL, VUE_WEB_BASEURL } from "../utils/api";
import axios from "axios";
export default {
  name: "Home",
  data: () => ({
    panel: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27,
    ],
    productRate: "0.00",
    productOfferPrice: "0.00",

    checkoutDialog: false,
    search: "",
    name: "",
    email: "",
    mobileno: "",
    state: "",
    deliverycity: "",
    address: "",
    checkbox: false,
    chechoutbtn: false,
    errorDialog: false,
    contactDialog: false,
    imageDialog: false,
    clickedImage: "",
    youtubeDialog: false,
    clickedUrl: "",
    popupDialog: false,
    otp: "",
    snackbar: false,
    text: "OTP Sent Successfully",
    timeout: 2000,
    menus: [
      {
        text: "Home",
        disabled: false,
        href: "#",
      },
      {
        text: "Contact",
        disabled: false,
        href: "contactus",
      },
    ],
    shipping_type: "",
    shipping_types: ["Pickup", "Delivery"],
    delivery_area: "",
    showDeliveryArea: false,
    delivery_charge: 0.0,
    total_Price: 0.0,
  }),
  computed: {
    ...mapGetters([
      "authStatus",
      "authStatusError",
      "authStatusresponse",
      "getProducts",
      "getBannerDetails",
      "getOrderStatus",
      "orderStatusResponse",
      "getDeliveryCost",
      "getOTP",
      "getBanners",
      "getCart",
    ]),
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    mobilenoErrors() {
      const errors = [];
      if (!this.$v.mobileno.$dirty) return errors;
      !this.$v.mobileno.required && errors.push("Mobileno is required.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("State is required.");
      return errors;
    },
    deliverycityErrors() {
      const errors = [];
      if (!this.$v.deliverycity.$dirty) return errors;
      !this.$v.deliverycity.required &&
        errors.push("Delivery City is required.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required && errors.push("Address is required.");
      return errors;
    },
    shippingTypeErrors() {
      const errors = [];
      if (!this.$v.shipping_type.$dirty) return errors;
      !this.$v.shipping_type.required &&
        errors.push("Shipping Type is required.");
      return errors;
    },
    deliveryAreaErrors() {
      const errors = [];
      if (!this.$v.delivery_area.$dirty) return errors;
      !this.$v.delivery_area.required &&
        errors.push("Delivery Area is required.");
      return errors;
    },
    otpErrors() {
      const errors = [];
      if (!this.$v.otp.$dirty) return errors;
      !this.$v.otp.required && errors.push("OTP is required");
      return errors;
    },
    loading: function () {
      return this.authStatus === "loading";
    },
    products: function () {
      return this.getProducts;
    },
    banner: function () {
      return this.getBannerDetails;
    },
    bannerImages: function () {
      return this.getBanners;
    },

    errorResponse: function () {
      console.log(this.getOrderStatus);
      return this.getOrderStatus === "error";
    },
    successResponse: function () {
      console.log(this.getOrderStatus);
      return this.getOrderStatus === "success";
    },
    cartError: function () {
      return this.orderStatusResponse;
    },

  },
  created() {
    const productFilter = {
      search: this.search,
      category: '',
    };
    this.fetchProducts(productFilter);

    this.fetchBanners();
    this.fetchBannerDetails();
    setTimeout(() => {
      this.allPanels();
      // this.popupDialog = true;
    }, 3000);

    this.fetchDeliveryCost();

    this.$store.subscribe((mutation) => {
      //console.log(mutation);
      if (mutation.type === "authError") {
        this.errorDialog = true;
      }

      if (mutation.type === "authRequest") {
        this.chechoutbtn = true;
      }

      if (
        mutation.type === "orderConfirmed" ||
        mutation.type === "orderConfirmedError"
      ) {
        this.chechoutbtn = false;
      }
    });
  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "fetchBanners",
      "fetchBannerDetails",
      "orderConfirm",
      "otpAction",
      "fetchDeliveryCost",
      "addTocart",

    ]),
    filterProducts() {
      const productFilter = {
        search: this.search,
        category: '',
      };

      this.fetchProducts(productFilter);
    },
    allPanels() {
      this.panel = [...Array(this.products.length).keys()].map((k, i) => i);
      //console.log(this.products.length);
    },
    increment(product) {
      // store.commit("authRequest");
      // console.log(product);
      product.qty = parseFloat(product.qty) + 1;
      product.totalRate = product.qty * product.rate;
      product.totalOfferPrice = product.qty * product.offerPrice;
      product.totalSalesPrice = product.qty * product.salesprice;
      this.addTocart(product);
      // this.calculateTotal();
      this.chechoutbtn = false;

    },
    decrement(product) {
      //  store.commit("authRequest");
      product.qty = product.qty === 0 ? 0 : parseFloat(product.qty) - 1;
      product.totalRate = product.qty * product.rate;
      product.totalOfferPrice = product.qty * product.offerPrice;
      product.totalSalesPrice = product.qty * product.salesprice;
      this.addTocart(product);
      // this.calculateTotal();
      this.chechoutbtn = false;
    },
    productDelete(counter) {
      //   console.log(counter);
      this.products.splice(counter, 1);
      this.calculateTotal();
    },
    productQtyUpdate(product) {
      //console.log(product);
      // console.log(event.target.value);
      // // store.commit("authRequest");
      // //check event.target.value is number or not

      if (event.target.value) {
        product.qty = product.qty < 0 ? 0 : parseInt(event.target.value);
        product.totalRate = product.qty * product.rate;
        product.totalOfferPrice = product.qty * product.offerPrice;
        product.totalSalesPrice = product.qty * product.salesprice;
        // this.calculateTotal();
      } else {
        product.qty = 0;
        product.totalRate = product.qty * product.rate;
        product.totalOfferPrice = product.qty * product.offerPrice;
        product.totalSalesPrice = product.qty * product.salesprice;
        // this.calculateTotal();
      }

      this.addTocart(product);

    },
    calculateTotal() {
      var totalQtyCalc;
      totalQtyCalc = this.products.reduce(function (categorysum, product) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var qty = parseFloat(product.qty);
          if (!isNaN(qty)) {
            return productsum + qty;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      }, 0);
      this.cartQty = totalQtyCalc;
      // console.log(totalQtyCalc);
      var totalRateCalc;
      totalRateCalc = this.products.reduce(function (categorysum, product) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var totalRate = parseFloat(product.totalRate);
          if (!isNaN(totalRate)) {
            return productsum + totalRate;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      }, 0);

      //console.log(totalRateCalc);

      this.productRate = totalRateCalc.toFixed(2);

      var totalOfferPriceCalc;
      totalOfferPriceCalc = this.products.reduce(function (
        categorysum,
        product
      ) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var totalOfferPrice = parseFloat(product.totalOfferPrice);
          if (!isNaN(totalOfferPrice)) {
            return productsum + totalOfferPrice;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      },
        0);

      //console.log(totalOfferPriceCalc);
      this.productOfferPrice = totalOfferPriceCalc.toFixed(2);

      var totalSalesPriceCalc;
      totalSalesPriceCalc = this.products.reduce(function (
        categorysum,
        product
      ) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var totalSalesPrice = parseFloat(product.totalSalesPrice);
          if (!isNaN(totalSalesPrice)) {
            return productsum + totalSalesPrice;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      },
        0);

      // console.log(totalSalesPriceCalc);

      this.productSalesPrice = totalSalesPriceCalc.toFixed(2);

      this.total_Price =
        parseFloat(this.productSalesPrice) + parseFloat(this.delivery_charge);
    },
    refereshPage() {
      window.location.reload();
    },
    popupCheckout() {
      this.checkoutDialog = true;
    },

    imageDialogOpen(image) {
      // console.log(image);
      this.clickedImage = image;
      this.imageDialog = true;
    },
    youtubeOpen(clickurl) {
      console.log(clickurl);

      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = clickurl.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      console.log("url", url);
      const YId =
        undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      console.log("YId", YId);
      if (YId === url[0]) {
        console.log("not a youtube link");
      } else {
        console.log("it's  a youtube video");
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      console.log("topOfQueue", topOfQueue);
      // this.result = topOfQueue;
      // this.youtubeURL = "";

      this.clickedUrl = topOfQueue;
      // window.open(url, "_blank");
      this.youtubeDialog = true;
    },
    youtubeDialogClose() {
      this.clickedUrl = '';
      this.youtubeDialog = false;
    },
    showDeliveryCostField() {
      if (this.shipping_type == "Pickup") {
        this.showDeliveryArea = false;
      } else {
        this.showDeliveryArea = true;
      }
    },
    getAreaDeliveryCharge() {
      console.log(this.delivery_area);
      if (this.delivery_area) {
        const funct = this;
        axios({
          method: "GET",
          url: VUE_APP_BASEURL + "delivery-costs/" + this.delivery_area,
        })
          .then(function (response) {
            console.log(response);
            funct.delivery_charge = response.data.response.price;
            funct.calculateTotal();
          })
          .catch(function (error) {
            //router.push("/");
            console.log(error);
          });
      } else {
        this.delivery_charge = 0;
        this.calculateTotal();
      }
    },
    priceListOpen() {
      window.open(
        VUE_WEB_BASEURL + "storage/pts-crackers-price-list-2024-updated.pdf",
        "_blank"
      );

    },
  },
  components: {
    MarqueeText,
  },
};
</script>
